body {
  color: var(--color-neutral-700);
  font-family: var(--font-family-body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-neutral-900);
}

.fontItalic {
  font-style: italic;
  font-synthesis: none;
}
