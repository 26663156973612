.card {
  border-radius: 8px;
  box-shadow: 2px 2px 5px 0 rgba(25, 33, 108, 0.15);
  max-width: 100%;
  overflow: hidden;
  width: 375px;
}

.figure {
  height: 150px;
  margin: 0;
  overflow: hidden;
}

.img {
  object-fit: cover;
  width: 100%;
}

.content {
  padding: var(--size-4);
}

.title {
  font-size: var(--font-size-5);
}
