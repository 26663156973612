.button {
  background-color: var(--buttonBgColor);
  border: 0;
  border-radius: 4px;
  color: var(--buttonColor);
  font-size: var(--font-size-2);
  font-weight: 550;
  padding: var(--size-3);
}

.outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--buttonColor);
}

.text {
  background-color: transparent;
}
