:root {
  /* Primary */
  --color-primary-050: #e0e8f9;
  --color-primary-100: #bed0f7;
  --color-primary-200: #98aeeb;
  --color-primary-300: #7b93db;
  --color-primary-400: #647acb;
  --color-primary-500: #4c63b6;
  --color-primary-600: #4055a8;
  --color-primary-700: #35469c;
  --color-primary-800: #2d3a8c;
  --color-primary-900: #19216c;

  /* Secondary */
  --color-secondary-050: #e1fcf8;
  --color-secondary-100: #c1fef6;
  --color-secondary-200: #92fdf2;
  --color-secondary-300: #62f4eb;
  --color-secondary-400: #3ae7e1;
  --color-secondary-500: #1cd4d4;
  --color-secondary-600: #0fb5ba;
  --color-secondary-700: #099aa4;
  --color-secondary-800: #07818f;
  --color-secondary-900: #05606e;

  /* Neutral */
  --color-neutral-050: #f5f7fa;
  --color-neutral-100: #e4e7eb;
  --color-neutral-200: #cbd2d9;
  --color-neutral-300: #9aa5b1;
  --color-neutral-400: #7b8794;
  --color-neutral-500: #616e7c;
  --color-neutral-600: #52606d;
  --color-neutral-700: #3e4c59;
  --color-neutral-800: #323f4b;
  --color-neutral-900: #1f2933;

  /* Accent */
  --color-accent-050: #ffe3ec;
  --color-accent-100: #ffb8d2;
  --color-accent-200: #ff8cba;
  --color-accent-300: #f364a2;
  --color-accent-400: #e8368f;
  --color-accent-500: #da127d;
  --color-accent-600: #bc0a6f;
  --color-accent-700: #a30664;
  --color-accent-800: #870557;
  --color-accent-900: #620042;

  /* Danger */
  --color-danger-050: #ffe3e3;
  --color-danger-100: #ffbdbd;
  --color-danger-200: #ff9b9b;
  --color-danger-300: #f86a6a;
  --color-danger-400: #ef4e4e;
  --color-danger-500: #e12d39;
  --color-danger-600: #cf1124;
  --color-danger-700: #ab091e;
  --color-danger-800: #8a041a;
  --color-danger-900: #610316;

  /* Warning */
  --color-warning-050: #fffbea;
  --color-warning-100: #fff3c4;
  --color-warning-200: #fce588;
  --color-warning-300: #fadb5f;
  --color-warning-400: #f7c948;
  --color-warning-500: #f0b429;
  --color-warning-600: #de911d;
  --color-warning-700: #cb6e17;
  --color-warning-800: #b44d12;
  --color-warning-900: #8d2b0b;

  /* Success */
  --color-success-050: #e3f9e5;
  --color-success-100: #c1f2c7;
  --color-success-200: #91e697;
  --color-success-300: #51ca58;
  --color-success-400: #31b237;
  --color-success-500: #18981d;
  --color-success-600: #0f8613;
  --color-success-700: #0e7817;
  --color-success-800: #07600e;
  --color-success-900: #014807;

  /* Palette */
  --color-primary: var(--color-primary-800);
  --color-secondary: var(--color-secondary-300);
  --color-accent: var(--color-accent-200);

  /* Fonts */
  --font-family-body: "Libre Franklin", sans-serif;

  /* Font Sizes */
  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 30px;
  --font-size-8: 36px;
  --font-size-9: 48px;
  --font-size-10: 60px;
  --font-size-11: 72px;

  /* Sizes */
  --size-1: 4px;
  --size-2: 8px;
  --size-3: 12px;
  --size-4: 16px;
  --size-5: 24px;
  --size-6: 32px;
  --size-7: 48px;
  --size-8: 64px;
  --size-9: 96px;
  --size-10: 128px;
  --size-11: 192px;
  --size-12: 256px;
  --size-13: 384px;
  --size-14: 512px;
  --size-15: 640px;
  --size-16: 768px;
}
